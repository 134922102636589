/*
 *  This file menu-codes.constant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

export const MENU_CODES = {
    CHAT: {
        CHAT: 'chat_151'
    },
    DASHBOARD: {
        HOME: 'home_1',
        SETUP: 'setup_2'
    },
    PROCESS: {
        PROFILE: 'profile_50',
        SAL_STRUC: 'salarystructure_113',
        SALARY_PROCESS: 'salarydetails_109',
        BONUS_PROCESS: 'bonusprocess_198',
        TIMESHEET: 'timesheet_2067',
        SAL_STRUC_HISTORY: 'salarystructurehistory_152',
        CONFIRM: 'confirmation_51',
        ACCEPT_RESIGNATION: "resignationacceptanceletter_2215",
        RESIGN: 'resignation_91',
        CANCELRESIGN: 'cancelresignation_156',
        CLEARANCE: 'clearance_89',
        FINALCLEARANCE: 'finalclearance_157',
        EXIT_INT: 'exitinterview_88',
        SEARCH: 'search_70',
        REPORT: 'reports_72',
        DASHBOARD: 'dashboard_120',
        INSIGHT: 'insights_71',
        EMP_LETTER: 'employeeletters_92',
        CONFIRMATION_LETTER: 'confirmationletter_97',
        OTHER_LETTER: 'otherletter_438',
        ATTENDANCE: 'attendance_103',
        PROCESS_ATT: 'processattendance_106',
        TERMINATION_LETTER: 'terminationletter_358',
        EXTENSION_LETTER: 'extensionletter_356',
        RELIEVING_LETTER: 'relievingletter_256',
        APPOINMENT_LETTER: "appointmentletter_272",
        LOAN: 'loan_112',
        LOANHISTORY: 'loan_235',
        LEAVEENCASHMENT: 'leaveencashment_115',
        SALATYYTD: 'salaryytd_178',
        REIMBURSEMENT: 'reimbursement_110',
        REIMBURSEMENTHISTORY: 'reimbursementhistory_170',
        SHIFT: 'shift_108',
        PROFILE_HISTORY: 'profilehistory_159',
        ANNOUNCEMENT: 'announcement_188',
        HR_POLICY: 'policy_189',
        BULK_PASS: 'sendbulkpassword_226',
        LEAVE_CARRY_FORWARD: "leavecarryforward_253",
        ATT_YTD: "attendanceytd",
        MARK_ATTENDANCE: 'attendance_342',
        SHIFT_CHANGE: 'shift_343',
        TRANSFER_REWARD_POINT: "transferreward_469",
        DISPENSEADVANCE: "dispenseadvance_773",
        EXTENDGOAL: "extendgoal_790",
        EXTENDREVIEW: "extendreview_791",
        ONBOARDINGSEARCH: "onboarding_776",
        PREBOARDING: "preboarding_779",
        EXPENSE_RECEIPT: 'expense_798',
        ADD_CANDIDATE: 'candidate_810',
        JOB_OPENING: 'jobopening_815',
        EXTENDTHREESIXTY: 'extendthreesixty_819',
        EXPENSE_ADVANCE_RECOVERY: 'expenseadvancerecovery_1336',
        EXPENSE_PAYMENT: 'expensepayment_818',
        CHANGE_MANAGER: 'employee_320',
        EDITCANDIDATE: 'editcandidate_848',
        ATTENDANCE_CALENDAR: "attendancecalendar_572",
        PULSE_SURVEY: 'pulsesurvey_583',
        CHANGE_GOAL: "goaledit_852",
        TIMESHEET_REQUEST: 'timesheet_2066',
        UPCOMING_EMP: "addupcomingjoin_732",
        GEN_LETTER: "generateletter_425",
        EXCEPTIONAL_LEAVE: "exceptionalleave_593",
        POSITION_ASSIGNED: "positionassigned_624",
        GEO_LOCATION: 'geolocations_661',
        TRANSFER: 'transferrequest_919',
        DO_TRANSFER: 'dotransfer_929',
        PERFORMANCE_LETTER: 'performanceletter_696',
        FBP_HISTORY: 'fbprequest_684',
        PUBLISH_LETTER: "publishletters_700",
        WELCOME_NOTES: "welcomenotesearch_704",
        SALARY_REVISION_LETTER: "salaryrevisionletter_709",
        REIMBURSHMENT_CANCEL: "reimbursement_cancel",
        PIP: "pip_955",
        CANDIDATEENGAGE: "candidateengagement_729",
        INITIATEGOALCYCLE: "initiategoalcycle_987",
        INITIATEREVIEWCYCLE: "initiatereviewcycle_988",
        VARIABLE: "variable_739",
        ADD_HARDWARE_INVENTORY: "addhardwareinventory_733",
        FUNDRAISER_CAMPAIGN: "fundraisercampaign_767",
        FEATURE_REQUEST: 'featurerequestcreate_1010',
        CREATECOMPANYGOAL: 'createcompanygoal_998',
        PIP_EVALUATION: "pip_975",
        BOOKTRAVEL: 'travelbooking',
        TRAVEL: 'travel_1016',
        ASSETREQUEST: "assetrequest_1028",
        ASSET: "assetsearch_1029",
        NORMALIZERATING: "normalizereviewrating_1021",
        NORMALIZEPERFORMANCERATING: "normalizeperformancerating_1022",
        PUBLISHPERFORMANCERATING: "publishperformancerating_1023",
        CREATE_VIRTUAL_TEAM: "okrteamgoal_1009",
        PIP_EVALUATION_LETTER: "pipletter_803",
        INITIATE360_FEEDBACK: "threesixtyfeedback_1057",
        OFFER_LETTER: "offerletter_832",
        EXTERNAL_CONSULTANT: "addexternal_1069",
        SHARED_LETTER: "sharedletter_1072",
        POSTTRANSFERSEARCH: "posttransfer_1103",
        PRETRANSFERSEARCH: "pretransfer_1104",
        INDUCTION: "induction_1086",
        INDUCTION_EVALUATION: "induction_1090",
        TRANSFER_LETTER: "transferletter_943",
        CONTRACT_LETTER: "contractletter_945",
        ACKNOWLEDGEMENT_LETTER: "acknowledgementletter_953",
        PIP_LETTER: "pipletter_955",
        RESTRICTED_HOLIDAY_CANCEL: "restrictedholidaycancel_971",
        WORK_FROM_HOME_SEARCH: "workplan_2052",
        DELETEREVIEW: "deletereview_2130",
        DELETEGOAL: "deletegoal_2129",
        ASSIGNHOLIDAY: "assignholiday_1160",
        INITIATIVES: "goalsandinitiatives_2207",
        PUBLISHED_LETTER_DOCUMENT: "publishedlettersdocuments_2203",
        HRDASHBOARD: "hrdashboard_1215",
        GENERATE_CHALLAN: 'challan_124',
        ASSET_ACKNOWLEDGEMENT: 'assetacknowledgement_2239',
        BGV_VERIFICATION: 'bgvsearch_2244',
        TRANSFER_INTIMATION_LETTER: 'transferintimationletter_2241',
        EVENTS_SEARCH: 'events_2277',
        EVENTS: 'event_1343',
        TALENTPOOL: 'talentpool_1355',
        REFER_EARN: 'refer_1398',
    },
    REQUEST: {
        TIMESHEET_REQUEST: 'timesheet_1014',
        RESIGN: 'resignation_69',
        REIMBURSEMENT: 'reimbursement_160',
        LOAN: 'loan_161',
        PENDING: 'pending_65',
        APPROVED: 'approved_66',
        REJECTED: 'rejected_67',
        DRAFT: 'drafts_68',
        UNDO: 'undo_126',
        RESTRICTED_HOLIDAY: 'restrictedholiday_172',
        ON_DUTY: 'onduty_193',
        PUR_INVEST: 'purposedinvestment_184',
        CON_INVEST: 'confirmedinvestment_185',
        ATTENDANCE_REGULARIZATION: 'attendanceregularization_192',
        LEAVE: 'leave_194',
        SHORT_LEAVE: 'shortleave_195',
        HELP_DESK_TICKET: 'helpdeskticket_373',
        EXPENSE_ADVANCE: "expenseadvance_478",
        EXPENSE_RECEIPT: "expensereceipt_494",
        JOB_OPENING: "jobopening_498",
        CHANGE_GOAL: "goaledit_576",
        VARIABLE: "variable_732",
        TRAVEL: 'travel_719',
        ASSET: "assetrequest_765",
        REQUEST: 'request_3',
        WFH_WORK_PLAN: 'workplan_999'
    },
    TEAM: {
        TEAM_ACTION: 'myteam_121',
        TEAM_PROFILE: 'teamprofile_551',
        SMART_SEARCH: 'smartsearch_555',
        MARK_ATTENDANCE_TEAM: 'markattendance_547',
        BULK_SHIFT_CHANGE: 'assignshift_546',
        PERFORMANCE: 'viewperformance_704',
        INITIATIVES: 'initiatives_548',
        ONEONONE: '1on1_549',
        THREESIXTYFEEDBACK: '360feedback_550',
        TEAM_REPORT: 'reports_601',
        LEAVE_CANCEL: 'leavecancel_2035',
        TRAININGS: 'training_2071',
        EXIT_INTERVIEW: 'exitinterview_2132',
        PIP: 'pip_2149',
        PIP_EVALUATION: 'pipevaluation_2142',
        GOAL_HISTORY: 'goalhistory_2150',
        EXPENSE_ADVANCE: 'expenseadvance_2143',
        EXPENSE_REPORT: 'expensereport_2144',
        TRAVEL: 'travel_2145',
        HELPDESK: 'helpdesk_2148',
        ASSET_REQUEST: 'assetrequest_2167',
        POSITION_ASSIGNED: 'positionassigned_2168',
        CONFIRMATION: 'confirmation_2131',
        MY_ASSET: 'myassets_1039',
        JOB_OPENING: 'jobopening_1018',
        GOALANDINITIATIVES: 'viewgoalsandinitiatives_702',
        CTC: 'team_588',
        BADGES_AND_REWARDS: 'viewbadgesandrewards_703',
        PROFILE: 'team_259',
        CLEARANCE: 'clearance_906',
        LEAVE_BALANCE: 'viewleavebalance_549',
        ATTENDANCE: 'viewattendance_550',
        SOCIAL_PROFILE: 'social_profile',
        ATTENDANCE_REGULARIZATION: 'attendanceregularization_2153',
        SHORT_LEAVE: 'shortleave_2165',
        LEAVE: 'leave_2156',
        OVER_TIME: 'overtime_2157',
        MARK_ATTENDANCE: 'markattendance_2162',
        COMPENSATORY_OFF: 'compensatoryoff_2155',
        ON_DUTY: 'onduty_2158',
        RESTRICTED_HOLIDAY: 'restrictedholiday_2163',
        LOCATION_APPROVAL: 'locationapproval_2161',
        SHIFT: 'shift_2164',
        HOLIDAY_CALENDER: 'holidaycalendar_2160',
        ATTENDANCEYTD: 'attendanceytd_2154',
        GEO_LOCATION: 'viewapprovedlocation_897',
        TICKET: 'ticket_2169',
        PAYSLIP: 'payslip_588',
        TEAM_PEOPLE: 'teampeople_1166',
        TEAM_OVERVIEW: "teamoverview_1174",
        TEAM_DASHBOARD: 'teamdashboard_1165',
        ASSIGN_HOLIDAY_TEAM: 'assignholiday_2227'
    },
    CORE: {
        ORG_STRUC: 'organizationstructure_11',
        ENTERPRISE: 'enterprise_21',
        COMPANY: 'company_22',
        BUSSINESS_UNIT: 'businessunit_23',
        DEPT: 'department_24',
        SUB_DEPT: 'subdepartment_25',
        DESIGNATION: 'designation_26',
        GRADE: 'grade_27',
        LEVEL: 'level_28',
        LOCATION: 'location_12',
        COUNTRY: 'country_29',
        STATE: 'state_30',
        CITY: 'city_31',
        REGION: 'region_32',
        BRANCH: 'branch_33',
        SUB_BRANCH: 'subbranch_34',
        ROLES: 'roles_13',
        STATUTORY: 'statutory_56',
        TAX: 'tax_57',
        CHALLAN: 'challan_58',
        SLAB: 'slabs_57',
        PAYGROUP: 'paygroup_59',
        ALERT: 'alerts_60',
        CONFIRM_POLICY: 'confirmationpolicy_40',
        SEP_SETTING: 'separationpolicy_41',
        SEP_POLICY: 'policy_137',
        SEP_REASON: 'reason_138',
        PRE_JOIN: 'prejoiningdocument_42',
        WORKFLOW_43: 'workflow_43',
        WORKFLOW_61: 'workflow_61',
        WORKFLOW_TIME_OFFICE: 'workflow_149',
        WORKFLOW_WORK_FROM_HOME: "workflow_1141",
        WORKFLOW_EXPENSE: "workflow_489",
        LETTER_TEMPLATE: 'lettertemplate_44',
        LETTER_TEMPLATE_425: 'lettertemplate_425',
        PAYSLIP_TEMPLATE: 'paysliptemplate_183',
        FEEDBACK_TEMPLATE: 'feedbacktemplate_45',
        ALL_FEEDBACK: 'allfeedback_62',
        QUES_BANK: 'questionbank_63',
        WF_ALERT_262: 'alertsandnotification_262',
        PM_NOTIFICATION: 'notification_900',
        PM_PROJECT_TEMPLATE: 'projecttemplate_986',
        TO_ALERT_265: 'alertsnotification_265',
        PR_ALERT_60: 'alertsandnotification_60',
        EN_ALERT_352: "notification_352",
        HD_ALERT_377: 'notification_377',
        PMS_ALERT_457: 'notification_457',
        EXPENSE_ALERT_519: 'notification_519',
        QUALIFICATION: 'qualification_47',
        CODE_SERIES: 'codeseries_14',
        EMAIL_CONF: 'emailsmsconfiguration_15',
        EMAIL: 'email_35',
        SMS: 'sms_36',
        FORM: 'forms_16',
        SERV: 'services_17',
        PAGE_ACCESS: 'pageaccess_18',
        DATA_ACCESS: 'dataaccess_19',
        GEO_ACCESS: "geoaccess_1294",
        WIDGET_ACCESS: 'widgetsaccess_578',
        AUDIT: 'audittrail_37',
        PAY_BANK: 'bank_147',
        BIOMETRIC: 'biometric_204',
        SETTING_WORKFORCE: 'settings_48',
        SETTING_TIMEOFFICE: 'setting_146',
        SETTING_PAYROLL: 'setting_182',
        HAPPINESS_INDEX_SETTING: 'happinessindex_341',
        ANALYTICSACCESS: 'analyticsaccess_20',
        LEAVE: 'leave_144',
        SERVICES: "services_17",
        AUDITTRAILSETTING: 'audittrail_271',
        HELPDESKPOLICY: 'policy_372',
        PERFORMANCE_SETTING: 'settings_379',
        SUBSCRIPTION: "subscription_384",
        PLAN: "plan_385",
        GOAL_BANK: 'goalbank_390',
        POLICY_360: 'threesixtypolicy_394',
        POLICY_360_LAYOUT: 'policythreesixty_395',
        POLICY_360_FEEDBACK: 'feedback_396',
        POLICY_360_QUESTION_BANK: 'questionbank_407',
        WORKFLOW_PERFORMANCE: 'workflow_406',
        GOAL_POLICY: 'goalpolicy_392',
        GOAL_TEMPLATE: 'goaltemplate_391',
        GOAL_SHEET: 'goalsheet_393',
        BADGE_SETTING: "badge_414",
        TIMESHEET_POLICY: 'timesheetpolicy_1006',
        POINTS_POLICT: "rewardpointspolicy_415",
        EN_SETTING: "settings_420",
        CHECK_LIST: "clearance_139",
        ONBOARDING: 'onboarding_471',
        EXPENSE_SETTING: 'settings_472',
        EXPENSE_CURRENCY: 'currency_517',
        ADVANCE_POLICY: "advancepolicy_476",
        PM_WORKFLOW: 'workflow_1007',
        LOCATION_GROUP: 'locationgroup_473',
        EXPENSE_POLICY: 'expensepolicy_477',
        EXPENSE_TAX: 'tax_490',
        CUSTOMER: "customer_491",
        PROJECT: "project_1262",
        PROJECT_FIELD: 'project_field_1263',
        PROJECT_SUB_STATUS: 'project_sub_status_1264',
        RECRUITMENT_SETTING: 'settings_513',
        WORKFLOW_RECRUITMENT: 'workflow_514',
        RECRUITMENT_TEMPLATE: 'feedbacktemplate_520',
        RECRUITMENT_FEEDBACK: 'feedback_521',
        RECRUITMENT_LETTERTEMPLATE: 'lettertemplate_545',
        RECRUITMENT_QUESTION_BANK: 'questionbank_522',
        DEVELOPER: 'developer_526',
        EMPLOYEE_OFFER: 'employeeoffer_1221',
        RECRUITMENT_SOURCE_SUBMENUE: "source_543",
        RECRUITMENT_SOURCE_CATEGORY: "sourcecategory_544",
        SINGLE_SIGN_ON: "singlesignon_532",
        ENGAGE_FEEDBACKTEMPLATE: "feedbacktemplate_579",
        ENGAGE_FEEDBACK: "allfeedback_580",
        ENGAGE_QUESTIONBANK: "questionbank_581",
        RECRUITMENT_ALERT_586: 'notification_586',
        TRANSFER: 'transfer_658',
        TRANSFER_REASON: 'reason_660',
        TRANSFER_POLICY: 'policy_659',
        PASSWORD_POLICY: "passwordpolicy_715",
        PIP_POLICY: "pip_716",
        ASSET_SETTING: "settings_726",
        ASSET_TYPE: "assettype_723",
        ASSET_INTERNAL_TYPE: "assettype_724",
        ASSET_INTERNAL_SUB_TYPE: "assettype_725",
        ASSET_PRODUCTS: "product_730",
        VENDOR: "vendor_1265",
        WORKFLOW_ASSET: "workflow_766",
        PRODUCTROAD_MAP: "productroadmap_780",
        ASSET_ALERT_810: "notification_810",
        EXPENSE_LETTER_TEMPLATE: "lettertemplate_814",
        DOWNLOAD_INVOICES: "downloadinvoice_815",
        PROJECTMANAGEMENT_SETTING: "settings_831",
        INDUCTION_POLICY: 'induction_868',
        REVIEW_RATING_FORMULA: 'reviewratingformula_884',
        WORK_FROM_HOME: 'settings_976',
        OFFICE_MASTER: 'officemaster_980',
        WFH_POLICY: 'policy_981',
        EMPLOYEE_ACCESS: 'employeeaccess_1037',
        CANDIDATE_ACCESS: 'candidateaccess_1046',
        CALENDAR_ACCESS: 'calendaraccess_1184',
        HELPDESK_SETTINGS: 'settings_959',
        SHORTCUT: 'shortcut_1',
        LETTER_TEMPLATE_ENGAGE: 'lettertemplate_1065',
        PROJECT_MANAGEMENT_TEMPLATE: 'lettertemplate_1346',
        SOCIAL_PROFILE_ACCESS: 'socialprofile_1080',
        CORE_VALUES: 'corevalue_967',
        WORK_FROM_HOME_ALERTS: 'notification_1020',
        BGV_INTEGRATION: 'bgvverification_1138',
        PMS_FEEDBACK_TEMPLATE: 'feedback_1200',
        ONBOARDING_PROCESS_CUSTOMER: "onboarding_1210",
        ONBOARDING_DASHBOARD: 'onboarding_892',
        SHIFT_ROTATION: 'shiftrotation_155',
        CHECK_IN_POLICY: 'checkinpolicy_148',
        SHORT_LEAVE: 'shortleave_150',
        HOLIDAY: 'holiday_145',
        ATTENDANCE: 'attendance_143',
        WEEKLY_OFF: 'weeklyoff_142',
        SHIFT: 'shift_141',
        CANDIDATE_ASSESSMENT: 'candidateassessment_1232',
        DIGITAL_SIGNATURE: 'digitalsignature_1243',
        SCIM_INTEGARATION: 'scimintegration_1245',
        JV_CONFIGURE_OU:'configure_ou_1384', 
        JV_CONFIGURE_EMPLOYEE:'employee_1385',
        CREATE_JV: 'create_jv_1383',
        BLACKLIST_REASON: 'blacklist_reason_1254',
        JOB_FUNCTION: 'job_function_1255',
        SKILL: 'skill_1256',
        SKILL_PROFICIENCY: 'skill_proficiency_1257',
        VENDOR_FIELD: "vendor_field_1266",
        TASK_AUTOMATION_WORKFORCE: 'workforce_1275',
        TASK_AUTOMATION_TIMEOFFICE: 'time_office_1276',
        TASK_AUTOMATION_WORKPLAN: 'work_plan_1277',
        TASK_AUTOMATION_PAYROLL: 'payroll_1278',
        TASK_AUTOMATION_EXPENSE: 'expense_1279',
        TASK_AUTOMATION_PERFORMANCE: 'performance_1280',
        TASK_AUTOMATION_RECRUITMENT: 'recruitment_1281',
        TASK_AUTOMATION_ASSET: 'asset_1282',
        GENERAL_PASSWORD_POLICY: 'password_policy_1285',
        DISABLE_LOGIN: 'disable_login_1286',
        LETTER_ACCEPTANCE: 'letter_acceptance_1291',
        INBOX_ACTIONS: 'inbox_actions_1287',
        ARCHIVE_INBOX_TASK: 'archive_inbox_task_1288',
        EMPLOYEE_REQUEST_LOCK: 'employee_request_lock_1289',
        TRANSFER_ALLOWANCES: 'transfer_allowances_1296',
        EKYC: 'ekyc_1297',
        CTC_FORMULA_TAGS: 'ctc_formula_tags_1300',
        OUT_OF_CTC_ALLOWANCES: 'out_of_ctc_allowances_1301',
        PERFORMANCE_METHODOLOGY: 'performance_methodology_1304',
        UOM: 'unit_of_measurement_1305',
        PERSPECTIVE: 'perspective_1306',
        REVIEW_RATING: 'review_rating_1308',
        COMPETENCY_RATING: 'competency_rating_1309',
        BELL_CURVE: 'bell_curve_1311',
        NINE_BOX: 'box_1312',
        INITIATIVE_TYPE: 'initiative_type_1314',
        PIP_REASON: 'pip_reason_1316',
        INVENTORY_FIELDS: 'inventory_1317',
        PROJECT_HEALTH: 'project_health_1325',
        PROJECT_MILESTONE: 'milestone_1318',
        INVOICE_DYNAMIC_FIELDS: 'invoice_dyn_field_1365',
        TASK_TYPE: 'task_type_1320',
        TASK_FIELDS: 'task_fields_1321',
        RATE_CARD: 'rate_card_1323',
        JOB_OPENING_FORM: 'job_opening_form_1328',
        JOB_OPENING_TAT: 'job_opening_tat_1329',
        CARRER_PORTAL: 'career_portal_1330',
        RECRUITMENT_BUDGET: 'recruitment_budget_1332',
        LINKEDIN_INTEGRATION: 'linkedin_integration_1331',
        EXPENSE_FORM: 'expense_form_1342',
        TRAVEL_PLAN_FORM: 'travel_plan_form_1396',
        ASSET_POLICY: 'policy_1347',
        CELEBRATION_SETUP: 'celebrationsetup_1382',
        ENGAGE_APP_VIEW: 'lmze_20',
        WORKFORCE_APP_VIEW: 'lmze_1',
        TIMEOFFICE_APP_VIEW: 'lmze_2',
        PAYROLL_APP_VIEW: 'lmze_3',
        PERFORMANCE_APP_VIEW: 'lmze_4',
        EXPENSE_APP_VIEW: 'lmze_5',
        HELPDESK_APP_VIEW: 'lmze_6',
        RECRUITMENT_APP_VIEW: 'lmze_9',
        WORKPLAN_APP_VIEW: 'lmze_23',
        ASSET_APP_VIEW: 'lmze_7',
        API_KEY_MANAGEMENT: 'apikeymanagement_1344'
    },
    ANALYTICS: {
        REPORTS: 'reports_72',
        HAPPINESSINDEX: 'happinessindex_276',
        ROI: 'roireturnofinvestment_278',
        EMPLOYEEMOVEMENT: 'employeemovement_279',
        EMPLOYEEEFFICIENCY: 'hrops_338',
        HELPDESK: 'helpdesk_427',
        PAYROLL: 'payroll_426',
        ENGAGE: "engage_279",
        PERFORMANCE: 'performance_439',
        NINE_BOX: 'ninebox_466',
        PULSE: "pulse_591",
        SURVEY: 'survey_592',
        EXPENSE: 'expense_596',
        RECRUITMENT: 'recruitment_600',
        TIMEOFFICE: 'timeoffice_632',
        POWERBI: 'powerbi_1076',

    },
    PROFILE: {
        PROFILE: 'profile_207',
        LETTER: 'letter_208',
        POLICY: 'policy_209',
        PAYSLIP: 'payslip_210',
        CALENDAR: 'calendar_224',
        LEAVEBALANCE: 'leavebalance_240',
        BADGESANDREWARDS: 'badgesandrewardpts_430',
        GOALSANDINITIATIVES: 'mygoalsandinitiatives_386',
        PERFORMANCE: 'performancereview_463',
        INITIATIVES: 'initiatives_548',
        ONEONONE: '1on1_549',
        THREESIXTYFEEDBACK: '360feedback_550',
        HOLIDAY: 'holiday_566',
        CTC: 'ctc_571',
        clearance: "clearance_646",
        GEO_LOCATION: "geolocation_646",
        JOB_OPENINGS: "jobopening_755",
        TRAVEL_TICKETS: 'ticket_786',
        ASSETS: "myassets_789",
        CONTINOUS_FEEDBACK: 'continuousfeedback_1076',
        TRAININGS: 'training_2037',
        //NEW PROFILE MENY CODES
        GOAL_HISTORY: 'goalhistory_1098',
        EXPENSE_ADVANCE: 'expenseadvance_1100',
        EXPENSE_REPORT: 'expensereport_1101',
        TRAVEL: 'travel_1111',
        CONFIRM_INVESTMENT_HISTORY: 'confirmedinvestmenthistory_1103',
        DISPENSE_LOAN: 'dispenseloan_1104',
        FBP_APPROVAL_HISTORY: 'fbpapprovalhistory_1108',
        LOAN_HISTROY: 'loanhistory_1105',
        CONFIRMATION: 'confirmation_1093',
        EXIT_INTERVIEW: 'exitinterview_1094',
        AR: 'attendanceregularization_1069',
        ATTENDANCE_YTD: 'attendanceytd_1074',
        CO: 'compensatoryoff_1081',
        LEAVE: 'leave_1071',
        LOCATION_APPROVAL: 'locationapproval_1078',
        MARK_ATTENDANCE: 'markattendance_1077',
        OD: 'onduty_1070',
        OVERTIME: 'overtime_1082',
        RESTRICTED_HOLIDAY: 'restrictedholiday_1083',
        SHIFT: 'shift_1084',
        SHORT_LEAVE: 'shortleave_1072',
        ASSEST_REQUEST: 'assetrequest_1113',
        PIP: 'pip_1097',
        PIP_EVALUATION: 'pipevaluation_1099',
        HELPDESK: 'helpdesk_1112',
        POSTION_ASSIGEND: 'positionassigned_1114',
        REIMBURSEMENT_HISTORY: 'reimbursementhistory_1107',
        SALARY_YTD: 'salaryytd_1109',
        VARIABLE_HISTORY: 'variablehistory_1110',
        PROPOSED_INVESTMENT_HISTORY: 'proposedinvestmenthistory_1106',
        SOCIAL_PROFILE: 'socialprofile_1122',
        BENEFITS: 'benefits_1162',
        EMPLOYEE_OFFER: 'employeeoffer_1228'
    },
    SETUP: {
        COMPENSATORYOFF: 'compensatoryoff_123',
        OVERTIME: 'overtime_241',
        SHORTLEAVE: 'shortleave_99',
        SHORTLEAVECANCEL: 'shortleavecancel_104',
        MARKATTENDNACE: 'markattendance_346',
        LOCATIONAPPROVAL: 'locationapproval_347',
        HELP_DESK_TICKET: 'helpdeskticket_376',
        NOMINATION: 'nomination_606',
        USER: 'user_3',
        PAGE_ACCESS_RIGHTS: 'page_access_rights_4',
        ANNOUNCEMENT: 'announcement_5',
        EVENT: 'event_6',
        HR_NPS: 'hrnps_9',
        EMPLOYEE_NPS: 'employeenps_10'
    }
};
